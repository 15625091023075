import React from "react"

export default function BooleanBuilder(props) {
  const {  jsonData } = props.object

  const { className, format, value, wrapElement } = JSON.parse(jsonData)

  if (wrapElement) return (
    <div
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      className={className}
    >
      {returnOutput(format, value)}
    </div>
  )
  return <React.Fragment>{returnOutput(format, value)}</React.Fragment>
}

  function returnOutput(format,value) {
    switch (value) {
      case true:
        if(format === 'yes/no') return 'Yes';
        if(format === 'true/false') return 'True';
        if(format === 'on/off') return 'On';
        break;
      case false:
          if(format === 'yes/no') return 'No';
        if(format === 'true/false') return 'False';
        if(format === 'on/off') return 'Off';
        break;

      default:
         if(format === 'yes/no') return 'Yes';
        if(format === 'true/false') return 'True';
        if(format === 'on/off') return 'On';
    }
  }