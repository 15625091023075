import React from "react"
// import "../../sytle/utilities.css"
import styled from "styled-components"
// import { motion } from "framer-motion"

const Back = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme};
  z-index: ${({ zIndex }) => (zIndex && zIndex)};
  /* opacity: 1; */
  transition: all 0.5s;
`

const Backdrop = ({ onClick, theme, zIndex, show }) => {
  return (
    <Back onClick={onClick} theme={theme} zIndex={zIndex} show={show}></Back>
  )
}

export default Backdrop
