import React from "react"

export default function PageCreatedBy(props) {
  const { className } = JSON.parse(props.object.jsonData)

  const page = props.page
  const pageData = props.pageData

  let value = new Date()
  if (page && page?.createdTime) {
    value = page?.createdTime
  } else {
    value = pageData?.createdTime
  }

  value = new Date(value).toLocaleDateString("en-US")

  return (
    <p
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      className={className}
    >
      {value}
    </p>
  )
}
