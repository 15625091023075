import React, { Suspense } from "react"
import Accordion from "react-bootstrap/Accordion"

export default function Item(props) {
  const { objectOrder, jsonData } = props.object
  const { bodyText, headerText } = JSON.parse(jsonData)
  const { answer, question } = JSON.parse(jsonData)

  let questionValue = (
    <div
      dangerouslySetInnerHTML={{
        __html: headerText || question,
      }}
    />
  )

  let answerValue = (
    <div
      dangerouslySetInnerHTML={{
        __html: bodyText || answer,
      }}
    />
  )

  return (
    <React.Fragment>
      <Suspense fallback={<div />}>
        <Accordion.Item eventKey={objectOrder}>
          <Accordion.Header>{questionValue}</Accordion.Header>
          <Accordion.Body>{answerValue}</Accordion.Body>
        </Accordion.Item>
      </Suspense>
    </React.Fragment>
  )
}
