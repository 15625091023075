import React from "react"
import { Badge } from "react-bootstrap"

function PhraseList(props) {
  const { uiObjectId, jsonData: object_config } = props.object
  const { style_type } = object_config

  let jsonData
  if(props && props.page && props.page.jsonData) {
    jsonData = props.page.jsonData
  }
  // const { jsonData } = props?.page
  let content = []
  let title = props.object.displayPhrase.phrase

  if (jsonData && jsonData[uiObjectId]) {
    content = jsonData[uiObjectId].content
  } else if (props?.pageData?.jsonData && props.pageData.jsonData[uiObjectId]) {
    content = props.pageData.jsonData[uiObjectId].content
  }

  switch (style_type) {
    case "list":
      return (
        <ul>
          <b>{title}</b>
          <br />
          {content.map(phrase => {
            return <li key={phrase.phraseId}>{phrase.phrase}</li>
          })}
        </ul>
      )

    case "bullet":
      return (
        <div>
          <b>{title}</b>
          <br />
          {content.map(phrase => {
            return (
              <div key={phrase.phraseId}>
                <span style={{ textAlign: "left" }}>{phrase.phrase}</span>
              </div>
            )
          })}
        </div>
      )
    default:
      return (
        <div>
          <b>{title}</b>
          <br />
          {content.map(phrase => {
            return (
              <div key={phrase.phraseId}>
                <span style={{ textAlign: "left" }}>{phrase.phrase}</span>
              </div>
            )
          })}
        </div>
      )
  }
}

export default PhraseList
