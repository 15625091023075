import React from "react"
import checkNesting from "../../checkNesting"
import useInlineStyle from "../../UseInlineStyle"

export default function OrderedList(props) {
  const { children, jsonData, uiObjectRelationId, objectName, uiObjectId } =
    JSON.parse(props.object)
  const { aria_role, customClassName, inlineStyle, type, start, reversed } =
    jsonData
  const className = customClassName

  const { styleObject } = useInlineStyle(inlineStyle)

  return (
    <ol
      id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
      className={className}
      reversed={reversed}
      start={start}
      style={styleObject}
      type={type}
      ariaRole={aria_role}
      data-primeagile-object={objectName}
    >
      {checkNesting(children, props.page)}
    </ol>
  )
}
