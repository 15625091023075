import React from "react"
import { Badge, Stack } from "react-bootstrap"

const PageTags = props => {
  const { jsonData } = props.object
  const { className, tags } = JSON.parse(jsonData)
 
  return (
    <Stack
      gap={1}
      direction="horizontal"
      className="justify-content-center flex-wrap"
    >
      {tags.map(tag => (
        <Badge
          key={tag.value.phraseId}
          className={`text-truncate ${className}`}
        >
          {tag.value.phrase}
        </Badge>
      ))}
    </Stack>
  )
}

export default PageTags
