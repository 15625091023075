import React from "react"

import * as fieldTypes from "./fieldTypes"

import PageTitle from "./Fields/Title"
import Description from "./Fields/Description"
import Author from "./Fields/Author"
import CreationTime from "./Fields/Created"
import ModificationTime from "./Fields/Modified"
import CoverImage from "./Fields/CoverImage"
import NonPublicData from "./Fields/NonPublicData"
import CoverPicture from "./Fields/CoverPicture"

export default function FieldTypeConfig(props) {
  const { field } = JSON.parse(props.object.jsonData)
  switch (field) {
    case fieldTypes.TITLE:
      return <PageTitle {...props} />
    case fieldTypes.DESCRIPTION:
      return <Description {...props} />
    case fieldTypes.AUTHOR:
      return <Author {...props} />
    case fieldTypes.CREATION_TIME:
      return <CreationTime {...props} />
    case fieldTypes.MODIFICATION_TIME:
      return <ModificationTime {...props} />
    case fieldTypes.COVER_IMAGE:
      return <CoverImage {...props} />
    case fieldTypes.COVER_PICTURE:
      return <CoverPicture {...props} />
    default:
      return "No Field Selected for Page Data!"
  }
}
