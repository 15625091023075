import React from "react"
import checkNesting from "../checkNesting"

export const PageStateContext = React.createContext(null)

function TextBlock(props) {
  let { uiObjectId, jsonData } = props.object
  const { value, pageVariable, websiteVariable, className } =
    JSON.parse(jsonData)

  let finalValue = ""

  if (websiteVariable) {
    const websiteVariables = props.websiteVariables
    finalValue = "websiteVariables[uiObjectId]"
      ? websiteVariables[uiObjectId]?.content
      : ""
    finalValue = (
      <div
        dangerouslySetInnerHTML={{
          __html: finalValue,
        }}
        id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
        className={className}
      />
    )
  } else if (pageVariable) {
    let value
    try {
      if (
        props.page &&
        props.page.jsonData &&
        props.page.jsonData[uiObjectId]
      ) {
        value = jsonData[uiObjectId].content
      } else if (
        props.pageData &&
        props.pageData.jsonData &&
        props.pageData.jsonData[uiObjectId]
      ) {
        value = props.pageData.jsonData[uiObjectId].content
      } else {
        value = ""
      }
    } catch (error) {}

    finalValue = (
      <div
        dangerouslySetInnerHTML={{
          __html: value,
        }}
        id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
        className={className}
      />
    )
  } else {
    finalValue = (
      <div
        id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
        className={className}
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    )
  }

  return (
    <React.Fragment>
      {finalValue}
      {checkNesting(props.object.children, props.page)}
    </React.Fragment>
  )
}

export default TextBlock
