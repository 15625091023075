import React, { useEffect } from "react"
// import Chidrens from "../Children/Children"
import { useKeycloak } from "@react-keycloak/web"
import { useDispatch} from "react-redux"
import { setKeepLogin } from "../../../../redux/slices/states"
import loadable from "@loadable/component"
const Chidrens = loadable(() => import("../Children/Children"))


const Login = props => {
  const { keycloak } = useKeycloak()
  const dispatch = useDispatch()
  const { jsonData } = props.object
  const { className } = JSON.parse(jsonData)
  const { children, uiObjectRelationId, objectName, uiObjectId } = props.object
  let styles
  let Data
  if (children && children[0]) {
    Data = children[0]?.jsonData
  }

  let as
 

  useEffect(() => {
    let mounted = true
    if (mounted) {
      dispatch(setKeepLogin(keycloak))
    }
    return () => {
      mounted = false
    }
  }, [dispatch, keycloak])


  return (
    <>
      <Chidrens
        as={as}
        styles={styles}
        uiObjectRelationId={uiObjectRelationId}
        children={children}
        props={props}
        objectName={objectName}
        uiObjectId={uiObjectId}
        className={className}
      />
    </>
  )
}

export default Login
