import React, { useEffect, useMemo } from "react"
// import ReactPlayer from "react-player"
import "./class.css"
import { useDispatch, useSelector } from "react-redux"
import { setPlayVideo, setVideoUrls } from "../../../../redux/slices/states"

const Video = props => {
  const isSSR = typeof window === "undefined"
  const ReactPlayer = React.lazy(() => import("react-player"))
  let { uiObjectId, jsonData } = props.object
  const {
    url,
    pageVariable,
    websiteVariable,
    classNames,
    playing,
    loop,
    controls,
    width,
    height,
    modalID,
  } = JSON.parse(jsonData)
  const dispatch = useDispatch()
  const { urls, player } = useSelector(state => state.state)
  const { modal } = useSelector(state => state.state)
  let play = modal.find(x => x.Id === parseInt(modalID))

  function handleSetUrl() {
    const data = {
      url,
      uiObjectId,
      modalID,
    }
    dispatch(setVideoUrls(data))
  }

  const handlPlay = () => {
    dispatch(setPlayVideo(play))
  }

  const filterurl = urls.filter(x => x.uiObjectId === uiObjectId)
  let findId = useMemo(() => {
    let id = player && player.Id
    let Id = parseInt(filterurl[0]?.modalID)
    let len = urls.length
    return len > 0
      ? Id === id
        ? player.value
        : playing
      : player && player.value
      ? player.value
      : playing
  }, [player])
  useEffect(() => {
    handleSetUrl()
  }, [])

  useEffect(() => {
    handlPlay()
  }, [play])

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={filterurl[0]?.url}
              playing={findId}
              loop={loop}
              controls={controls}
              width={width ? width : "100%"}
              height={height ? height : "100%"}
              volume={1}
            />
          </div>
        </React.Suspense>
      )}
    </>
  )
}

export default Video
