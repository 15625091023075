import React from "react"

function RawData(props) {
  const { uiObjectId, jsonData } = props.object
  const {
    data: value,
    pageVariable,
    websiteVariable,
    wrapElement,
    className,
  } = JSON.parse(jsonData)

  let finalValue = ""

  if (websiteVariable) {
    const websiteVariables = props.websiteVariables
    finalValue = websiteVariables[uiObjectId]
      ? websiteVariables[uiObjectId].content
      : ""
  } else if (pageVariable) {
    let value
    try {
      const { jsonData } = props.pageData

      if (jsonData[uiObjectId]) {
        value = jsonData[uiObjectId].content
      } else if (
        props?.pageData?.jsonData &&
        props.pageData.jsonData[uiObjectId]
      ) {
        value = props.pageData.jsonData[uiObjectId].content
      } else {
        value = ""
      }
      finalValue = value
    } catch (error) {
      finalValue = ""
    }
  } else {
    finalValue = value
  }

  if (!wrapElement) return <React.Fragment>{finalValue}</React.Fragment>
  return (
    <div
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      className={className}
    >
      {finalValue}
    </div>
  )
}

export default RawData
