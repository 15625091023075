import React from "react"
import { Link } from "gatsby"

export default function PageTitle(props) {
  const { config, className } = JSON.parse(props.object.jsonData)
  const { headerType, asLink } = config;
  const page = props.page;
  const pageData = props.pageData;

  let value = <div className="alert alert-warning">NO TITLE AVAILABLE</div>;
  let url = !asLink && null;
  if (page && page.title && page.title.phrase) {
    value = page.title.phrase
    url = page.url.phrase;
  } else if (pageData.title){
    value = pageData.title
    url = pageData.url
  } 


  switch(headerType){
    case "h1":
      return (
        <React.Fragment>
          {asLink ? (
            <Link
              id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
              className={className}
              to={url}
            >
              {value}
            </Link>
          ) : (
            <h1 className={className}>{value}</h1>
          )}
        </React.Fragment>
      )
    case "h2":
      return (
        <React.Fragment>
          {asLink ? (
            <Link
              id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
              className={className}
              to={url}
            >
              {value}
            </Link>
          ) : (
            <h2 className={className}>{value}</h2>
          )}
        </React.Fragment>
      )
    case "h3":
      return (
        <React.Fragment>
          {asLink ? (
            <Link
              id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
              className={className}
              to={url}
            >
              {value}
            </Link>
          ) : (
            <h3 className={className}>{value}</h3>
          )}
        </React.Fragment>
      )
    case "h4":
      return (
        <React.Fragment>
          {asLink ? (
            <Link
              id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
              className={className}
              to={url}
            >
              {value}
            </Link>
          ) : (
            <h4 className={className}>{value}</h4>
          )}
        </React.Fragment>
      )
    case "h5":
      return (
        <React.Fragment>
          {asLink ? (
            <Link
              id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
              className={className}
              to={url}
            >
              {value}
            </Link>
          ) : (
            <h5 className={className}>{value}</h5>
          )}
        </React.Fragment>
      )
    case "h6":
      return (
        <React.Fragment>
          {asLink ? (
            <Link
              id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
              className={className}
              to={url}
            >
              {value}
            </Link>
          ) : (
            <h6 className={className}>{value}</h6>
          )}
        </React.Fragment>
      )
    default:
      return (
        <React.Fragment>
          {asLink ? (
            <Link
              id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
              className={className}
              to={url}
            >
              {value}
            </Link>
          ) : (
            <h1 className={className}>{value}</h1>
          )}
        </React.Fragment>
      )
  }
  
}
