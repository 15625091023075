import React from "react"
import { Link } from 'gatsby'
export default function PageLink(props) {
  const { inlineStyle, className } = JSON.parse(props.object.jsonData)
  let styles
  try {
    styles = JSON.parse(inlineStyle)
    //
  } catch (error) {}
  if (props.page.url) {
    return (
      <Link
        to={ props.page.url.phrase}
        style={styles}
        id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
        className={`${className}`}
      >
        {props.page.title.phrase}
      </Link>)
  }
  return <Link
      to={ props.pageData.url}
      style={styles}
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      className={`${className}`}
    >
      {props.pageData.title}
    </Link>

}
