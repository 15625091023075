import React, { useState } from "react"
import styled from "styled-components"

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: ${props => (props.width ? props.width : "70px")};
  height: ${props => (props.height ? props.height : "30px")};

  input {
    opacity: 0;
    width: 0;
    height: 0;
    -webkit-transform: ${props => props.checked && "translateX(40px)"};
    -ms-transform: ${props => props.checked && "translateX(40px)"};
    transform: ${props => props.checked && "translateX(40px)"};
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: ${props =>
      props.checked ? props.checkedBackground : props.notCheckedBackgroud};

    ::before {
      position: absolute;
      content: "";
      height: 29px;
      width: 29px;
      left: 0px;
      bottom: 1px;
      background-color: ${props =>
        props.buttonColor ? props.buttonColor : "white"};
      -webkit-transition: 0.4s;
      transition: 0.4s;
      -webkit-transform: ${props => props.checked && "translateX(40px)"};
      -ms-transform: ${props => props.checked && "translateX(40px)"};
      transform: ${props => props.checked && "translateX(40px)"};
    }
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`

const Toggle = ({ object }) => {
  const { jsonData } = object
  const {
    className,
    id,
    websiteVariable,
    pageVariable,
    toggle,
    width,
    height,
    buttonColor,
    checkedBackground,
    notCheckedBackgroud,
  } = JSON.parse(jsonData)
  const [checkToggle, setCheckToogle] = useState(toggle)
  const handleCheck = e => {
    setCheckToogle(e.target.checked)
  }

  return (
    <div>
      <Label
        checked={checkToggle}
        htmlFor="toggle"
        className={className}
        width={width}
        height={height}
        checkedBackground={checkedBackground}
        notCheckedBackgroud={notCheckedBackgroud}
        buttonColor={buttonColor}
      >
        <input
          id="toggle"
          type="checkbox"
          checked={checkToggle}
          onChange={handleCheck}
        />
        <span class="slider round"></span>
      </Label>
    </div>
  )
}

export default Toggle
