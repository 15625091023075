import React from "react"

export default function FormSelectList(props) {
  const { uiObjectId, uiObjectRelationId, jsonData } = props.object
  const { className } = JSON.parse(jsonData)

  const value =
    (props.pageData.jsonData &&
      props.pageData.jsonData[uiObjectId] &&
      props.pageData.jsonData[uiObjectId].content) ||
    ""


  return (
    <p
      id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
      className={className || ""}
    >
      {value}
    </p>
  )
}
