import React from "react";

export default function PageDescription(props) {
  const { inlineStyle, className } = JSON.parse(props.object.jsonData)
  let styles
  try {
    styles = JSON.parse(inlineStyle)
    //
  } catch (error) {}
  return (
    <div
      style={styles}
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      className={`${className}`}
    >
      {props.page.metaDescription}
    </div>
  )
}
