import React from "react"

export default function SVG(props) {
  const {
    jsonData
  } = props.object
  const { content } = jsonData

  return (
      <div dangerouslySetInnerHTML={{ __html: content }} />
  )
}
