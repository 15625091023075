import React from "react"
import { Link } from "gatsby"
import { useEffect } from "react"
import { useState } from "react"

export default function NavigationItem(props) {
  const {
    text: title,
    link,
    className,
    inlineStyle,
    newTab,
  } = JSON.parse(props.object.jsonData)

  let styles = {}

  try {
    styles = JSON.parse(inlineStyle)
  } catch (error) {}

  const [additionalClass, setAdditionalClass] = useState("")

  const path = window.location.pathname

  useEffect(() => {
    if (
      (path.length > 1 ? path.substring(0, path.length - 1) : path) === link
    ) {
      setAdditionalClass("active")
    } else {
      setAdditionalClass("")
    }
  })

  return (
    <>
      {newTab ? (
        <a
          id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
          style={{ ...styles }}
          href={link}
          target="_blank"
          className={className + additionalClass}
        >
          {title}
        </a>
      ) : (
        <Link
          id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
          style={{ ...styles }}
          to={link}
          className={className + additionalClass}
        >
          {title}
        </Link>
      )}
    </>
  )
}
