import React from 'react';
//import '@fortawesome/fontawesome-free/js/all.js';

export default function Icon(props) {
  const { inlineStyle, customClassName, name } = JSON.parse(
    props.object.jsonData
  )
 let styles
 try {
   styles = JSON.parse(inlineStyle)
  //  
 } catch (error) {
   
 }
  return (
    <i
      style={styles}
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      className={`${name} ${customClassName}`}
    ></i>
  )
}
