import React, { Suspense } from "react"
import { useEffect } from "react"
import checkNesting from "../../checkNesting"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useState } from "react"
const Slider = React.lazy(() => import("react-slick"))
// const Slider = React.lazy(() =>
//   import("react-slick").then(module => {
//     return { default: module.default }
//   })
// )
const CarouselBuilder = props => {
  const isSSR = typeof window === "undefined"
  const { jsonData, children } = props.object
  const settings_ = JSON.parse(jsonData)

  //! add fade transition to state

  //set state
  const [state, setstate] = useState({})
  useEffect(() => {
    setstate({
      dots: true,
      infinite: true,
      rows: 1,
      slidesToShow: parseInt(settings_.slidesPerRow),
      slidesToScroll: parseInt(settings_.slidesToScroll),
      autoplay: true,
      speed: parseInt(settings_.speed),
      autoplaySpeed: parseInt(settings_.autoplaySpeed),
      className: settings_.className,
    })
  }, [])

  //change slides to chow if not medium upon load
  useEffect(() => {
    if (!state.dots) return
    if (
      window.innerWidth < 641 &&
      Number(state.slidesToShow) != Number(settings_.slidesPerRowSmall) &&
      settings_.slidesPerRowSmall
    ) {
      setstate({ ...state, slidesToShow: Number(settings_.slidesPerRowSmall) })
    } else if (
      window.innerWidth < 1008 &&
      window.innerWidth > 640 &&
      Number(state.slidesToShow) != Number(settings_.slidesPerRowMedium) &&
      settings_.slidesPerRowMedium
    ) {
      setstate({ ...state, slidesToShow: Number(settings_.slidesPerRowMedium) })
    }
  }, [state])

  //change slides to show on page resize
  useEffect(() => {
    const slideShown = () => {
      if (!state.dots) return
      if (
        window.innerWidth < 641 &&
        Number(state.slidesToShow) != Number(settings_.slidesPerRowSmall) &&
        settings_.slidesPerRowSmall
      ) {
        setstate({
          ...state,
          slidesToShow: Number(settings_.slidesPerRowSmall),
        })
      } else if (
        window.innerWidth > 1007 &&
        Number(state.slidesToShow) != Number(settings_.slidesPerRow) &&
        settings_.slidesPerRow
      ) {
        setstate({ ...state, slidesToShow: Number(settings_.slidesPerRow) })
      } else if (
        window.innerWidth < 1008 &&
        window.innerWidth > 640 &&
        Number(state.slidesToShow) != Number(settings_.slidesPerRowMedium) &&
        settings_.slidesPerRowMedium
      ) {
        setstate({
          ...state,
          slidesToShow: Number(settings_.slidesPerRowMedium),
        })
      }
    }

    window.addEventListener("resize", slideShown)

    return () => {
      window.removeEventListener("resize", slideShown)
    }
  }, [state])

  useEffect(() => {
    if (settings_.scrollShow && state && state.slidesToShow) {
      const slides = state.slidesToShow
      setstate({ ...state, slidesToScroll: slides })
    }
  }, [state.slidesToShow])

  //return carousel
  return (
    <>
      {!isSSR && (
        <Suspense>
          <Slider
            id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
            {...state}
          >
            {children?.map(child => {
              return (
                <div
                  key={child.uiObjectId}
                  className="carousel_item_primeagile"
                >
                  {checkNesting(child.children)}
                </div>
              )
            })}
          </Slider>
        </Suspense>
      )}
    </>
  )
}

export default CarouselBuilder
