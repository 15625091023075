export const TITLE = "TITLE"
export const DESCRIPTION = "DESCRIPTION"
export const IMAGE = "IMAGE"
export const JSON_DATA = "JSON_DATA"
export const AUTHOR = "AUTHOR"
export const CATEGORY = "CATEGORY"
export const CREATION_TIME = "CREATION_TIME"
export const MODIFICATION_TIME = "MODIFICATION_TIME"
export const COVER_IMAGE = "COVER_IMAGE"
export const COVER_PICTURE = "COVER_PICTURE"
export const NON_PUBLIC_DATA = "NON_PUBLIC_DATA"
