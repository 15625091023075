import React from "react"

export default function PageCoverImage(props) {
  const { className, config } = JSON.parse(props.object.jsonData)
  const page = props.page
  const pageData = props.pageData

 
  let value = <div className="alert alert-warning">NO TITLE AVAILABLE</div>
  let url = ""
  let altText = ""
  if (page && page?.CoverImagePicture?.coverPictureAltText) {
    altText = page.CoverImagePicture.coverPictureAltText
  }
  if (page && page?.coverImageUrl) {
    url = page.coverImageUrl
  } else if (page?.coverImageUrl) {
    url = pageData.coverImageUrl
  } else if (config?.href) {
    url = config.href
  }

  return (
    <img
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      src={url}
      alt={altText}
      className={className}
      loading="lazy"
    />
  )
}
