import React from 'react'

export default function Time(props) {
  
  const { time, text} = props?.object?.jsonData;

  return (
    <time dateTime={time}>{text}</time>
  )
}
