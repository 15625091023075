import React from 'react'
import checkNesting from "../checkNesting"

export default function Headers(props) {

   const {
     children,
     jsonData,
     objectName,
   } = props.object;

   const {
     size,
     className,
     inlineStyle,
     textContent,
     pageVariable,
   } = JSON.parse(jsonData)

   let styles;

   try {
     styles = JSON.parse(inlineStyle)
  
   } catch (error) {
     
   }
    let finalContent = ""
    if (pageVariable) {
      let value

      const { jsonData } = props.page

      if (jsonData && jsonData[props.object.uiObjectId]) {
        value = jsonData[props.object.uiObjectId].content
      } else if (
        props.pageData &&
        props.pageData.jsonData &&
        props.pageData.jsonData[props.object.uiObjectId]
      ) {
        value = props.pageData.jsonData[props.object.uiObjectId].content
      } else {
        value = ""
      }
      finalContent = value
    } else {
      finalContent = textContent
    }

   
  switch (size) {
    case "h1":
      return (
        <h1
          data-primeagile-object={objectName}
          className={className}
          style={styles}
                id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}

        >
          {finalContent} {checkNesting(children,props.page)}
        </h1>
      )
    case "h2":
      return (
        <h2
          data-primeagile-object={objectName}
          className={className}
          style={styles}
                id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}

        >
          {finalContent} {checkNesting(children,props.page)}
        </h2>
      )
    case "h3":
      return (
        <h3
          data-primeagile-object={objectName}
          className={className}
          style={styles}
                id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}

        >
          {finalContent} {checkNesting(children,props.page)}
        </h3>
      )
    case "h4":
      return (
        <h4
          data-primeagile-object={objectName}
          className={className}
          style={styles}
                id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}

        >
          {finalContent} {checkNesting(children,props.page)}
        </h4>
      )
    case "h5":
      return (
        <h5
          data-primeagile-object={objectName}
          className={className}
          style={styles}
                id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}

        >
          {finalContent} {checkNesting(children,props.page)}
        </h5>
      )
    case "h6":
      return (
        <h6
          data-primeagile-object={objectName}
          className={className}
          style={styles}
                id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}

        >
          {finalContent} {checkNesting(children,props.page)}
        </h6>
      )
    default:
      return (
        <h1
          data-primeagile-object={objectName}
          className={className}
          style={styles}
                id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}

        >
          {finalContent} {checkNesting(children,props.page)}
        </h1>
      )
  }
}
