import uiObjectInstanceFactory from "./index"
//import _ from "lodash";
import { sortBy } from "lodash"

export default function checkNesting(children, page = {}) {
  if (children && children.length) {
    //const sortedNodes = _.sortBy(children, ["objectOrder"])
    const sortedNodes = sortBy(children, ["objectOrder"])
    return uiObjectInstanceFactory(sortedNodes, page)
  }
}
