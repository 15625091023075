import React, { Suspense } from "react"

function PageListing(props) {
  const isSSR = typeof window === "undefined"

  const List = React.lazy(() => import("./loadListing"))

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <List props={props} />
        </Suspense>
      )}
    </>
  )
}

export default PageListing
