import React from "react"

export default function PageAuthor(props) {
  const { className } = JSON.parse(props.object.jsonData)

  const page = props.page
  const pageData = props.pageData

  let value = ''
  if (page && page.createdBy) {
    value = page.createdBy
  } else {
    value = pageData.createdBy
  }

  return (
    <p
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      className={className}
    >
      {value}
    </p>
  )
}
