
const NonPublicData = () => {
//   const { jsonData } = props.object
//   const { targetEmail, pageVariable, websiteVariable, layoutVariable } =
//     JSON.parse(jsonData)

  return null
}

export default NonPublicData
