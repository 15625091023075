const useInlineStyle = (inlineStyle = "") => {
  var newInlineStyle = inlineStyle
    .toString()
    .replaceAll(":", ",")
    .replaceAll("{", "")
    .replaceAll("}", "")
    .replaceAll('"', "")
    .replaceAll(" ", "")

  var temporary = newInlineStyle.split(",")
  var styleObject = {}

  for (let i = 0; i < temporary.length; i += 2) {
    styleObject[temporary[i]] = temporary[i + 1]
  }

  return { styleObject }
}

export default useInlineStyle
