import React from "react"

function Phrase(props) {
  const { uiObjectId, jsonData } = props.object

  const { value, pageVariable, websiteVariable } = JSON.parse(jsonData)

  let finalValue = ""

  if (websiteVariable) {
    const websiteVariables = props.websiteVariables
    finalValue = websiteVariables[uiObjectId]
      ? websiteVariables[uiObjectId].content
      : ""
  } else if (pageVariable) {
    let value
    try {
      if (props.page.jsonData) {
        const { jsonData } = props.page
        value = jsonData[uiObjectId].content
      } else if (
        props?.pageData?.jsonData &&
        props.pageData.jsonData[uiObjectId]
      ) {
        value = props.pageData.jsonData[uiObjectId].content
      } else {
        value = ""
      }
      finalValue = value.phrase || value
    } catch (error) {
      finalValue = ""
    }
  } else {
    finalValue = value
  }

  // return <>he</>

  return <React.Fragment>{finalValue}</React.Fragment>
}

export default Phrase
