import React from "react"
const Bread = React.lazy(() => import("./Crumbs"))

export default function BreadCrumb(props) {
  const isSSR = typeof window === "undefined"
  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Bread props={props} />
        </React.Suspense>
      )}
    </>
  )
}
