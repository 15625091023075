import React, { Suspense } from "react"

import Accordion from "react-bootstrap/Accordion"
import checkNesting from "../../checkNesting"

const Accordian = props => {
  const { jsonData, children } = props.object
  const { defaultActiveKey } = JSON.parse(jsonData)

  return (
      <Accordion defaultActiveKey={defaultActiveKey}>
        {checkNesting(children)}
      </Accordion>
  )
}

export default Accordian