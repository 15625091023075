import React, { Suspense, useEffect, useState } from "react"

export default function Button(props) {
  const isSSR = typeof window === "undefined"
  const Btn = React.lazy(() => import("./lazyButton"))

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <Btn props={props} />
        </Suspense>
      )}
    </>
  )
}
