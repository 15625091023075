import React from "react"

function Script(props) {
  const { uiObjectId, jsonData } = props.object

  const { script: value, pageVariable, websiteVariable } = JSON.parse(jsonData)

  let finalValue = ""

  if (websiteVariable) {
    const websiteVariables = props.websiteVariables
    finalValue = websiteVariables[uiObjectId]
      ? websiteVariables[uiObjectId].content
      : ""
  } else if (pageVariable) {
    let value
    try {
      const { jsonData } = props.pageData

      if (jsonData[uiObjectId]) {
        value = jsonData[uiObjectId].content
      } else if (
        props?.pageData?.jsonData &&
        props.pageData.jsonData[uiObjectId]
      ) {
        value = props.pageData.jsonData[uiObjectId].content
      } else {
        value = ""
      }
      finalValue = value
    } catch (error) {
      finalValue = ""
    }
  } else {
    finalValue = value
  }

  React.useEffect(() => {
    if (typeof finalValue === "string" && finalValue.trim() !== "") {
      var scriptTag = document.createElement("script")
      scriptTag.type = "text/javascript"
      scriptTag.text = finalValue
      document.body.appendChild(scriptTag)

      return () => {
        document.body.removeChild(scriptTag)
      }
    }
  }, [finalValue])

  return <React.Fragment></React.Fragment>
}

export default Script
