import { createContext } from "react"
import React from "react"

export const PageStateContext = createContext()

export const ProviderAPI = ({ children, value }) => {
  return (
    <PageStateContext.Provider value={value}>
      {children}
    </PageStateContext.Provider>
  )
}

// const PageStateContext = createContext(null)
// export default PageStateContext
