import React from "react"
import { Container } from "react-bootstrap"
import checkNesting from "../../checkNesting"
import useInlineStyle from "../../UseInlineStyle"
function ContainerBuilder(props) {
  const { fluid, className, inlineStyle } = JSON.parse(props.object.jsonData)

  const { styleObject } = useInlineStyle(inlineStyle)

  return (
    <Container
      className={className}
      fluid={fluid}
      style={{
        paddingLeft: fluid && "0",
        paddingRight: fluid && "0",
        ...styleObject,
      }}
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
    >
      {props.object.children && checkNesting(props.object.children, props.page)}
    </Container>
  )
}

export default ContainerBuilder
