import React from "react"
import checkNesting from "../../checkNesting"
import useInlineStyle from "../../UseInlineStyle"

export default function List(props) {
  const {
    children,
    jsonData,
    uiObjectRelationId,
    objectName,
    uiObjectId,
  } = props.object
  const {
    aria_role,
    customClassName,
    inlineStyle,
    type,
    value
  } = JSON.parse(jsonData)
  const className = customClassName

  const {styleObject} = useInlineStyle(inlineStyle)

  return (
    <li
      id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
      className={className}
      style={styleObject}
      value={value}
      type={type}
      ariaRole={aria_role}
      data-primeagile-object={objectName}
    >
      {checkNesting(children, props.page)}
    </li>
  )
}
