import React from "react"
import { Col } from "react-bootstrap"
import checkNesting from "../../checkNesting"
import useInlineStyle from "../../UseInlineStyle"

function ColBuilder(props) {
  const {
    className,
    inlineStyle,
    lg,
    md,
    sm,
    xl,
    xs,
    xxl,
  } = JSON.parse(props.object.jsonData)


const { styleObject } = useInlineStyle(inlineStyle)

  return (
    <Col
      className={className}
      style={styleObject}
      lg={Number(lg) || String(lg) ||  12}
      md={Number(md) || String(md) || 12}
      sm={Number(sm) || String(sm) || 12}
      xl={Number(xl) || String(xl) || 12}
      xs={Number(xs) || String(xs) || 12}
      xxl={Number(xxl) || String(xxl) || 12}
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
    >
      {props.object.children && checkNesting(props.object.children,props.page)}
    </Col>
  )
}

export default ColBuilder
