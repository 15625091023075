import React, { Suspense } from "react"

export default function FormatedDate(props) {
  const isSSR = typeof window === "undefined"
  const Dates = React.lazy(() => import("./lazyFormattedDate"))

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <Dates props={props} />
        </Suspense>
      )}
    </>
  )
}
