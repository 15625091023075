import axios from "axios"

export default function (
  url,
  method,
  data,
  params = null,
  done,
  errorhandler,
  config = undefined,
  onUploadProgress = undefined
) {
  let headers = { Accept: "*/*" }

  const instance = axios.create({
    timeout: 19500,
    headers,
    data,
    params,
  })
    instance.defaults.headers.common = {
      // Authorization: `Bearer ${token}`,
      tenant: process.env.GATSBY_BUILDSITE
    }

  instance[method](url, data, { ...config, onUploadProgress })
    .then(response => {
      return done(response)
    })
    .catch(error => {
      if (typeof errorhandler === "function") {
        return errorhandler(error)
      }
    })
}
