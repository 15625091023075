import React from "react"
import checkNesting from "../../checkNesting"
import useInlineStyle from "../../UseInlineStyle"

export default function UnorderedList(props) {
  const { children, jsonData, uiObjectRelationId, uiObjectId } = props.object
  const { aria_role, className, inlineStyle, type } = JSON.parse(jsonData)

  const { styleObject } = useInlineStyle(inlineStyle)

  return (
    <ul
      id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
      className={className}
      style={styleObject}
      type={type}
      ariaRole={aria_role}
    >
      {checkNesting(children, props.page)}
    </ul>
  )
}
