import React, { useEffect } from "react"
import { useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { Link } from "gatsby"
import Pagination from "../PageListing/Components/Pagination"
import useInlineStyle from "../../UseInlineStyle"

const Container = styled.div`
  display: ${({ len }) => (len > 0 ? "grid" : "block")};
  display: -ms-grid;
  display: -moz-grid;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: 1230px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 920px) and (max-width: 1229px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 630px) and (max-width: 919px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
    width: 273px;
    text-align: center;
    display: inline-grid;
    margin: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    height: fit-content;
    :hover {
      box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
    }
  }

  a {
    text-align: center;
    text-decoration: none;
    color: #05847b;
    font-size: 14px;

    :hover {
      color: #05847b;
    }
  }
`

const DynamicContainer = props => {
  const { result, searchQuery } = useSelector(state => state.search)
  const [state, setState] = useState({
    allData: [],
    inview: [],
  })
  const { jsonData, uiObjectRelationId, objectName, uiObjectId } = props.object
  const { className, inlineStyle } = JSON.parse(jsonData)

  useEffect(() => {
    if (result?.content?.length > 0) {
      setState({
        allData: result?.content,
        inview: result?.content?.slice(0, 10),
      })
    }
  }, [result])

  function handleSetItems(data) {
    setState(pr => {
      return {
        ...pr,
        inview: data,
      }
    })
  }

  const { styleObject } = useInlineStyle(inlineStyle)

  return (
    <div
      className={className}
      id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
      style={{ marginTop: "100px", marginBottom: "3rem", ...styleObject }}
      data-primeagile-object={objectName}
    >
      <Container len={state?.inview?.length}>
        {state?.inview?.length > 0 ? (
          <>
            {state?.inview?.map(item => {
              return (
                <div key={item.pageId}>
                  <div className="card product-list-card" key={item.pageId}>
                    <div className="text-center">
                      {item.coverImageUrl && (
                        <img
                          loading="lazy"
                          src={item.coverImageUrl}
                          alt={item.title.phrase}
                        />
                      )}

                      <Link to={item.url.phrase} className={className}>
                        {item.title.phrase}
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </>
        ) : (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1> No Result Found</h1>
          </div>
        )}
      </Container>
      <div style={{ margin: "1.5rem 0" }}>
        <Pagination
          items={state.allData}
          itemsPerPage={10}
          setCurrentItems={handleSetItems}
        />
      </div>
    </div>
  )
}

export default DynamicContainer
