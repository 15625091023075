import React from "react"
import { Form } from "react-bootstrap"
import useInlineStyle from "../../UseInlineStyle"

export default function FormInput(props) {
  const { jsonData, objectName } = props.object
  const { placeholder, customClassName, inlineStyle, label, type, name } =
    jsonData
  const className = customClassName
 
  const { styleObject } = useInlineStyle(inlineStyle)

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        data-primeagile-object={objectName}
        id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
        style={styleObject}
        className={className}
        type={type}
        name={name}
        placeholder={placeholder}
      />
    </Form.Group>
  )
}
