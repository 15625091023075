import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import checkNesting from "../../checkNesting"
import { Link } from "gatsby"
//import "@fortawesome/fontawesome-free/js/all.js"

function NavBarBuilder(props) {
  const {
    variant,
    expand,
    brandlink,
    brand,
    fixed,
    fixedPosition,
    className,
    inlineStyle,
    sticky,
    stickyPosition,
    imageWidth,
    imageHeight,
  } = JSON.parse(props.object.jsonData)
  let styles = {}


  try {
    styles = JSON.parse(inlineStyle)
  } catch (error) {}

  const brandOutput = (
    <img
      height={imageHeight}
      width={imageWidth}
      className="d-inline-block align-top brand-image"
      src={brand}
      alt={brand}
      loading="lazy"
    />
  )
  return (
    <Navbar
      style={{ width: "100%", ...styles }}
      variant={variant}
      className={className}
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      expand={expand}
      fixed={fixed && fixedPosition}
      role="navigation"
      bg={variant}
      sticky={sticky && stickyPosition}
    >
      <Navbar.Brand>
        <Link to={brandlink}>
        {brandOutput}

        </Link>
        </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        className="justify-content-end"
        id="responsive-navbar-nav"
      >
        <Nav>
          {props.object.children && checkNesting(props.object.children)}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavBarBuilder
