import React from 'react'
import checkNesting from "../../checkNesting"

export default function item(props) {
    const { children } = props.object

  return (
    <>
      {checkNesting(children)}
      </>
  )
}
