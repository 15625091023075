import React from 'react'

export default function FormSelectList(props) {
  const { uiObjectId, jsonData, uiObjectRelationId, } = props.object
  const { list, className } = JSON.parse(jsonData)
 
  return (
    <select
      className={className}
      id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
    >
      {list.map((item, index) => {
        return (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        )
      })}
    </select>
  )
}
