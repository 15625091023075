import React from "react"

export default function PageDescription(props) {

  const { config, className } = JSON.parse(props.object.jsonData)
  const { length } = config
  const page = props.page
  const pageData = props.pageData
  let value = <div className="alert alert-warning">NO DESCRIPTION AVAILABLE</div>
  if (page && page.metaDescription) {
    value = page.metaDescription
  } else {
    value = pageData.description
  }
  
  return (
    <p
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      className={className}
    >
      {`${value} ${value && value.length > length ? "..." : ""}`}
    </p>
  )
}
