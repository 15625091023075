import React, { Suspense } from "react"


function InputBuilder(props) {
  const isSSR = typeof window === "undefined"
  const Input = React.lazy(() => import("./lazyInput"))

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <Input props={props} />
        </Suspense>
      )}
    </>
  )
}
export default InputBuilder
