import React from "react"

export default function Date(props) {
  const {  customClassName } = JSON.parse(props?.object?.jsonData)

  const { uiObjectId, jsonData } = props.object

  const { value, pageVariable, websiteVariable } = JSON.parse(jsonData)

  let finalValue = ""

  if (websiteVariable) {
    const websiteVariables = props.websiteVariables
    finalValue = websiteVariables[uiObjectId]
      ? websiteVariables[uiObjectId].content
      : ""
  } else if (pageVariable) {
    let value
    try {
      if (props.page.jsonData) {
        const { jsonData } = props.page
        value = jsonData[uiObjectId].content
      } else if (
        props?.pageData?.jsonData &&
        props.pageData.jsonData[uiObjectId]
      ) {
        value = props.pageData.jsonData[uiObjectId].content
      } else {
        value = ""
      }
      finalValue = value.phrase || value
    } catch (error) {
      finalValue = ""
    }
  } else {
    finalValue = value
  }

  return (
    <span
      className={customClassName}
      dateTime={finalValue}
      style={{ margin: "0 10px" }}
    >
      {finalValue}
    </span>
  )
}
