// import React, { Suspense, useEffect, useState } from "react"

// const Drawer = props => {
//   const isSSR = typeof window === "undefined"

//   const Drawerr = React.lazy(() => import("./Drawer"))
//   return (
//     <>
//       {!isSSR && (
//         <Suspense fallback={<div />}>
//           <Drawerr props={props} />
//         </Suspense>
//       )}
//     </>
//   )
// }

// export default Drawer

import React, { Suspense, useEffect, useState } from "react"
import styled from "styled-components"
import Backdrop from "../Backdrop/Backdrop"
import { useSelector, useDispatch } from "react-redux"
import { CloseToggleDrawer, SetDrawers } from "../../../../redux/slices/states"
// import Chidrens from "../Children/Children"
import loadable from "@loadable/component"
import useInlineStyle from "../../UseInlineStyle"
const Chidrens = loadable(() => import("../Children/Children"))

const DrawerNav = styled.nav`
  box-shadow: ${props => props.boxShadow};
  position: fixed;
  transform: ${props => props.show && "translateZ(0)!important;"};
  transition: all;
  transition-duration: ${props =>
    props.transitionDuration ? props.transitionDuration : "500ms"};
  z-index: ${props => props.zIndex};
  border-radius: ${props => props.borderRadius};
  background: ${props => props.background};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  overflow-y: hidden;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 7px;
    padding: 0;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 1px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
  &:hover,
  &:active,
  &:focus {
    overflow-y: auto;
  }
`

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.color};
  font-family: ${props => props.font};
  justify-content: ${props =>
    props.position === "center"
      ? "center"
      : props.position === "right"
      ? "end"
      : props.position === "left" && "start"};
  font-weight: 700;
  font-size: 25px;
  transform: translate(0, 0px);
`

const ButtonDiv = styled.div`
  display: flex;
  display: -webkit-flex;

  justify-content: ${props =>
    props.position === "right" ? "end" : props.position === "left" && "start"};

  span {
    background: #fff;
    padding: 2px;
    border-radius: 44px;
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 1;
  }
`

const Drawer = props => {
  const isSSR = typeof window === "undefined"

  const Draws = React.lazy(() => import("./lazyDrawer"))
  // const isSSR = typeof window === "undefined"
  // const dispatch = useDispatch()
  // const { drawers } = useSelector(state => state.state)
  // const CloseIcon = loadable(() => import("@mui/icons-material/Close"))

  // const { jsonData } = props.object
  // const {
  //   id,
  //   className,
  //   direction,
  //   width,
  //   height,
  //   backdrop_color,
  //   margin,
  //   padding,
  //   background,
  //   boxShadow,
  //   borderRadius,
  //   transitionDuration,
  //   zIndex,
  //   closeButton,
  //   closeButtonPosition,
  //   buttonClassName,
  //   drawerHeaderClassName,

  //   drawerHeaderText,

  //   showOverlay,
  //   defaultValue,

  //   inlineStyleOverlay,
  // } = JSON.parse(jsonData)
  // const buttonState = closeButton === "yes" ? true : false
  // const [buttonClose] = useState(buttonState)

  // //! VERY IMPORTANT
  // const { children, uiObjectRelationId, objectName, uiObjectId } = props.object
  // //! END

  // let styles
  // let Data
  // if (children && children[0]) {
  //   Data = children[0]?.jsonData
  // }

  // let as
  // let inlineStyle
  // if (Data) {
  //   as = JSON?.parse(Data)?.as
  //   inlineStyle = JSON?.parse(Data)?.inlineStyle
  // }

  // if (String(inlineStyle).trim() !== "{}") {
  //   try {
  //     styles = JSON?.parse(inlineStyle)
  //   } catch (error) {}
  // }

  // const getDirection = () => {
  //   switch (direction) {
  //     case "left":
  //       return {
  //         top: 0,
  //         left: 0,
  //         transform: "translate3d(-100%, 0, 0)",
  //         width: width,
  //         height: height,
  //       }

  //     case "right":
  //       return {
  //         top: 0,
  //         right: 0,
  //         transform: "translate3d(100%, 0, 0)",
  //         width: width,
  //         height: height,
  //       }
  //     case "bottom":
  //       return {
  //         left: 0,
  //         right: 0,
  //         bottom: 0,
  //         transform: "translate3d(0, 100%, 0)",
  //         width: width,
  //         height: height,
  //       }
  //     case "top":
  //       return {
  //         left: 0,
  //         right: 0,
  //         top: 0,
  //         transform: "translate3d(0, -100%, 0)",
  //         width: width,
  //         height: height,
  //       }

  //     default:
  //       break
  //   }
  // }

  // const handleClose = () => {
  //   dispatch(CloseToggleDrawer(uiObjectId))
  // }

  // useEffect(() => {
  //   const data = {
  //     Id: uiObjectId,
  //     value: defaultValue,
  //   }
  //   dispatch(SetDrawers(data))
  // }, [])

  // let getStyles = getDirection()

  // const {styleObject} = useInlineStyle(inlineStyleOverlay)

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <Draws props={props} />
        </Suspense>
        // <>
        //   {drawers?.find(item => item.Id === uiObjectId)?.value &&
        //     showOverlay === "yes" && (
        //       <Backdrop onClick={handleClose} theme={backdrop_color} />
        //     )}
        //   <DrawerNav
        //     role="navigation"
        //     id={id}
        //     className={className}
        //     show={drawers?.find(item => item.Id === uiObjectId)?.value}
        //     style={{ ...getStyles, ...styleObject }}
        //     margin={margin}
        //     padding={padding}
        //     background={background}
        //     boxShadow={boxShadow}
        //     borderRadius={borderRadius}
        //     transitionDuration={transitionDuration}
        //     zIndex={zIndex}
        //   >
        //     {buttonClose && (
        //       <ButtonDiv position={closeButtonPosition}>
        //         <span
        //           className={buttonClassName}
        //           onClick={handleClose}
        //           role="button"
        //         >
        //           <CloseIcon />
        //         </span>
        //       </ButtonDiv>
        //     )}

        //     {drawerHeaderText && (
        //       <DrawerHeader className={drawerHeaderClassName}>
        //         {drawerHeaderText}
        //       </DrawerHeader>
        //     )}

        //     <Chidrens
        //       as={as}
        //       styles={styles}
        //       uiObjectRelationId={uiObjectRelationId}
        //       children={children}
        //       props={props}
        //       objectName={objectName}
        //       uiObjectId={uiObjectId}
        //       className={className}
        //     />
        //   </DrawerNav>
        // </>
      )}
    </>
  )
}

export default Drawer
