import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setPage } from "../../../../redux/slices/pages"

import FieldConfig from "./FieldConfig"

function PageDataBuilder(props) {
  const dispatch = useDispatch()
  let usedChild = []
  let childJson = []
  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (
        props?.object?.displayPhrase === "test html header" &&
        props?.pageData?.site === "10951"
      ) {
        usedChild = [props?.pageData?.jsonData]
        let test = usedChild[0]

        for (let [key, value] of Object.entries(test)) {
          childJson = [
            ...childJson,
            {
              id: key,
              type: value.type,
              content: value.content,
            },
          ]
        }


        const name = props?.pageData?.pageId
        const title = props?.pageData?.title
        const image = props?.pageData?.coverImageUrl
        const pageUrl = props?.pageData?.url
        dispatch(setPage({ childJson, name, image, title, pageUrl }))
      }
    }
    return () => {
      mounted = false
    }
  }, [dispatch, props?.pageData?.jsonData, props?.object?.displayPhrase])

  return <FieldConfig {...props} />
}
export default PageDataBuilder
