import React from "react"
import styled from "styled-components"
import { OpenToggleDrawer } from "../../../../redux/slices/states"
import { increase, decrease, setUserCart } from "../../../../redux/slices/pages"
//import "@fortawesome/fontawesome-free/js/all.js"
import { useDispatch, useSelector } from "react-redux"

const Container = styled.div`
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  width: 120px;
  border-radius: 38px;
  height: 50px;
  padding:  7 !important;
  background: #fff;

  #pads {
    padding:  7 !important;

  }

  button {
    border: none;
    background-color: transparent;
    padding: 0 !important;
  }

  p {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 600;
  }

  svg {
    font-size: 1.5rem;
  }
`

const CartButton = () => {
  
  const { Id, userCart } = useSelector(state => state.page)
  const { drawerId } = useSelector(state => state.state)

  const quantity = userCart?.find(item => item.name === Id)?.quantity || 1
  const dispatch = useDispatch()
  // const { id = "#####SWww", amount } = useSelector(state => state.cart)

  const increaseAmount = () => {
    let check = userCart?.find(item => item.name === Id)
    if (check) {
      dispatch(increase(Id))
    } else {
      
      dispatch(OpenToggleDrawer(parseInt(drawerId)))
      dispatch(setUserCart(Id))
    }
  }

  const decreaseAmount = () => {
    if (quantity > 1) {
      dispatch(decrease(Id))
    }
  }

  //disable button if no stock 4dconcepts
  let btndisabled = false;
  if(document.getElementById('primeagile__15053__13203') && 
  document.getElementById('primeagile__15053__13203').innerText && 
  document.getElementById('primeagile__15053__13203').innerText == '0'){
    btndisabled = true;
  }

  return (
    <Container style={{padding: '6px !important'}} id='pads'>
      <button className="remove-btn" onClick={decreaseAmount} disabled={btndisabled} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 1024 1024"
        >
          <path
            fill="currentColor"
            d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"
          />
        </svg>
      </button>
      <p className="amount">{quantity}</p>
      <button className="amount-btn" onClick={increaseAmount} disabled={btndisabled}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet" 
          viewBox="0 0 32 32"
        >
          <path fill="currentColor" d="M17 15V8h-2v7H8v2h7v7h2v-7h7v-2z" />
        </svg>
      </button>
    </Container>
  )
}

export default CartButton;
