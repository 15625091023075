import React, { Suspense } from "react"

const StepperComponent = props => {
  const isSSR = typeof window === "undefined"
  const Stepper = React.lazy(() => import("./lazyStepper"))

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <Stepper props={props} />
        </Suspense>
      )}
    </>
  )
}

export default StepperComponent
