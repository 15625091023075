import React from "react"
import { Form } from "react-bootstrap"
import useInlineStyle from "../../UseInlineStyle"

export default function FormTextArea(props) {
  const { jsonData, objectName } = props.object
  const { placeholder, className, inlineStyle, label, name, required } =
    JSON.parse(jsonData)

  const { styleObject } = useInlineStyle(inlineStyle)

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        data-primeagile-object={objectName}
        id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
        style={styleObject}
        className={`${className} ${required && 'required'}`}
        as="textarea"
        placeholder={placeholder}
        rows={4}
        name={name}
      />
    </Form.Group>
  )
}
