import React from "react"
import { NavDropdown } from "react-bootstrap"



export default function NavigationDropDownItem__BUILDER(props) {
  const { href, displayText, divider } = JSON.parse(props.object.jsonData)

  return (
    <React.Fragment>
      <NavDropdown.Item
        href={href}
        id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      >
        {displayText}
      </NavDropdown.Item>
      {divider && <NavDropdown.Divider />}
    </React.Fragment>
  )
}
