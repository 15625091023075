import React, { Suspense } from "react"

export default function MediaWithPreview(props) {
  const isSSR = typeof window === "undefined"
  const Media = React.lazy(() => import("./lazyMedia"))
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <Media props={props} />
        </Suspense>
      )}
    </>
  )
}
