import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { OpenToggleDrawer } from "../../../../redux/slices/states"
import styled from "styled-components"
import loadable from "@loadable/component"


const Stack = styled.div`
    color: rgba(66, 130, 205, 0.86);
    position: relative;
    cursor: pointer;

  span {
    background-color: red;
    color: white;
    width: 20px;
    height: 20px;
    font-size: ${props => props.circleFontSize ? props.circleFontSize : "11px"};
    border-radius: 26px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: absolute;
    left: 16px;
    top: -6px;

  }
`



const DynamicIcon = props => {
  const dispatch = useDispatch()
  const ShoppingCartIcon = loadable(() => import("@mui/icons-material/ShoppingCart")) 
  
  const { jsonData } = props.object
  const {
    className,
    targetID,
    Color,
    Width,
    Height,
    circleColor,
    BackgroundColor,
    circleBackgroundColor,
    circleFontSize,
    circleRight,
    circleTop,
    cirleLeft,
    circleBottom,
    profileIcon,
  } = JSON.parse(jsonData)

  const { userCart } = useSelector(state => state.page)

  // let sum_of_quantities = 0

  const openDrawer = () => {
    if (targetID) {
      dispatch(OpenToggleDrawer(parseInt(targetID)))
    }
  }
  const quantity = userCart?.length || 0
  


 
  const { children, uiObjectRelationId, objectName, uiObjectId } = props.object

  let styles
  let Data
  if (children && children[0]) {
    Data = children[0]?.jsonData
  }

  let as
  let inlineStyle
  if (Data) {
    as = JSON?.parse(Data)?.as
    inlineStyle = JSON?.parse(Data)?.inlineStyle
  }

  if (String(inlineStyle).trim() !== "{}") {
    try {
      styles = JSON?.parse(inlineStyle)
    } catch (error) {}
  }


  return (
    <React.Fragment>
    
        <Stack
            onClick={openDrawer}
           circleColor={circleColor}
           circleBackgroundColor={circleBackgroundColor}
           circleFontSize={circleFontSize}
           circleRight={circleRight}
           circleTop={circleTop}
           cirleLeft={cirleLeft}
           circleBottom={circleBottom}
           
        >
          <span>{quantity}</span>
          <ShoppingCartIcon fontSize="large" />

        </Stack>
     
    </React.Fragment>
  )
}
export default DynamicIcon

