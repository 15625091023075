import React from "react"
import { Row } from "react-bootstrap"
import checkNesting from "../../checkNesting"
import useInlineStyle from "../../UseInlineStyle"

function RowBuilder(props) {
  const { className, inlineStyle } = JSON.parse(props.object.jsonData)

  const { styleObject } = useInlineStyle(inlineStyle)

  return (
    <Row
      style={styleObject}
      className={className}
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
    >
      {props.object.children && checkNesting(props.object.children, props.page)}
    </Row>
  )
}

export default RowBuilder
