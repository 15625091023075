import React from "react"
import checkNesting from "../checkNesting"
import { Link } from "gatsby"
import https from "../../../services/https"




export default function AnchorLink(props) {


  const {
    text,
    href,
    inlineStyle,
    className,
    pageVariable,
    external,
    activeStyle,
    activeClassName,
  } = JSON.parse(props.object.jsonData)

  let styles = {}
  let activestyle = {}
  try {
    styles = JSON.parse(inlineStyle)
    activestyle = JSON.parse(activeStyle)
  } catch (error) {
    //
  }



  let finalHref = ""
  let finalLinkText = ""
  if (pageVariable) {
    //  const { jsonData: jsonData } = props.page
    const { jsonData } = props?.pageData?.jsonData

    if (jsonData && jsonData[props.object.uiObjectId]) {
      finalHref = jsonData[props.object.uiObjectId].content.href
      finalLinkText = jsonData[props.object.uiObjectId].content.text
    } else if (
      props.pageData &&
      props.pageData.jsonData &&
      props.pageData.jsonData[props.object.uiObjectId]
    ) {
      finalHref = props.pageData.jsonData[props.object.uiObjectId].content.href
      finalLinkText =
        props.pageData.jsonData[props.object.uiObjectId].content.text
    }
  } else {
    finalHref = href
    finalLinkText = text
  }

  if (finalHref === "" || finalHref === null) {
    return <React.Fragment></React.Fragment>
  } else if (external) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={finalHref}
        style={styles}
        activeclassname={activeClassName}
        activestyle={{ ...activestyle }}
        id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
        className={`${className}`}
      >
        {finalLinkText}
        {checkNesting(props.object.children, props.page)}
      </a>
    )
  } else
    return (
      <Link
        to={finalHref}
        style={styles}
        activeclassname={activeClassName}
        activeStyle={{ ...activestyle }}
        id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
        className={`${className}`}
        partiallyActive={true}
      >
        {finalLinkText}
        {checkNesting(props.object.children, props.page)}
      </Link>
    )
}
