import React from "react"

export default function PageCoverPicture(props) {
  const { className, config } = JSON.parse(props.object.jsonData)
  const page = props.page

  let altText = "Page Cover Picture"
  if (page && page?.CoverImagePicture?.coverPictureAltText) {
    altText = page.CoverImagePicture.coverPictureAltText
  }

  let image = page && page?.CoverImagePicture?.pictureData

  return (
    <picture
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      className={className}
    >

    {image && image.otherUrls && image.otherUrls.source ? image.otherUrls.source.map((item, index) => {
      return (
        <source
          type={item.fileType}
          key={index}
          srcSet={item.srcSet}
          media={item.media}
        />
      )
    }):<source
    srcSet={image && image.otherUrls && image.otherUrls.img ? image.otherUrls.img.src : image && image.originalImageUrl}
    media={"(min-width:650px)"}
    />}
      <img
        src={
          image && image.otherUrls && image.otherUrls.img ? image.otherUrls.img.src : image && image.originalImageURl
        }
        alt={altText}
        loading="lazy"
      />
    </picture>
  )
}
