import React from "react"

export default function Picture(props) {
  const { image, className, alt } = JSON.parse(props.object.jsonData)

  return (
    <picture
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
      className={className}
    >

    {image.otherUrls && image.otherUrls.source ? image.otherUrls.source.map((item, index) => {
      return (
        <source
          type={item.fileType}
          key={index}
          srcSet={item.srcSet}
          media={item.media}
        />
      )
    }):<source
    srcSet={image.otherUrls && image.otherUrls.img ? image.otherUrls.img.src : image.originalImageURl}
    media={"(min-width:650px)"}
    />}
      <img
        src={
          image.otherUrls && image.otherUrls.img ? image.otherUrls.img.src : image.originalImageURl
        }
        alt={image.altText ? image.altText.phrase : alt}
        loading="lazy"
      />
    </picture>
  )
}
